import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Box, Text, Link, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 80px 0",
	"sm-padding": "30px 0 30px 0",
	"md-padding": "40px 0 40px 0",
	"quarkly-title": "About-13"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "grid",
			"grid-gap": "32px",
			"sm-grid-gap": "16px",
			"grid-template-columns": "4fr 5fr",
			"align-items": "center",
			"width": "50%",
			"lg-width": "100%",
			"lg-grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://ultravizo.com/images/3.jpg",
			"object-fit": "cover",
			"width": "100%",
			"height": "80%",
			"lg-height": "100%"
		}
	},
	"image1": {
		"kind": "Image",
		"props": {
			"src": "https://ultravizo.com/images/4.jpg",
			"object-fit": "cover",
			"width": "100%",
			"height": "100%",
			"max-height": "460px"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "50%",
			"flex-direction": "column",
			"justify-content": "center",
			"align-items": "flex-start",
			"lg-align-items": "center",
			"padding": "42px 42px 42px 42px",
			"lg-width": "100%",
			"md-padding": "24px 24px 24px 24px",
			"md-margin": "24px 0px 0px 0px",
			"sm-align-items": "flex-start",
			"sm-padding": "24px 24px 24px 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 16px 0px",
			"color": "--dark",
			"font": "--base",
			"lg-text-align": "center",
			"text-transform": "uppercase",
			"letter-spacing": "2px",
			"sm-text-align": "left",
			"children": "Не чекайте більше на поїздку всього життя."
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 24px 0px",
			"color": "--dark",
			"font": "--headline3",
			"lg-text-align": "center",
			"sm-text-align": "left",
			"children": "Готові до поїздки?"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 40px 0px",
			"color": "--greyD2",
			"font": "--base",
			"lg-text-align": "center",
			"sm-text-align": "left",
			"children": "Зв'яжіться з нами зараз і відкрийте для себе радість відкритої дороги з Race Art Rentals. Поїдьте з нами, де кожна подорож - це свобода та захоплення. Відкрийте для себе пристрасть до верхової їзди вже сьогодні!"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "/contacts",
			"color": "--lightD1",
			"text-decoration-line": "initial",
			"padding": "11px 24px 12px 24px",
			"background": "--color-indigo",
			"font": "--base",
			"transition": "--opacityOut",
			"hover-opacity": ".7",
			"border-radius": "36px",
			"letter-spacing": "1px",
			"children": "Контакти"
		}
	}
};

const Contact = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
		<Box {...override("box")}>
			<Image {...override("image")} />
			<Image {...override("image1")} />
		</Box>
		<Box {...override("box1")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
			<Text {...override("text2")} />
			<Link {...override("link")} />
		</Box>
		{children}
	</Section>;
};

Object.assign(Contact, { ...Section,
	defaultProps,
	overrides
});
export default Contact;